import {I18n} from '@lingui/core';
import {CurrencyCode} from '@zentact/common';

export const getCurrencyCodeTranslation = (cur: CurrencyCode, i18n: I18n) => {
  const translations: {[_ in CurrencyCode]: string} = {
    [CurrencyCode.USD]: i18n._('US Dollar'),
    [CurrencyCode.CAD]: i18n._('Canadian Dollar'),
    [CurrencyCode.EUR]: i18n._('Euro'),
    [CurrencyCode.GBP]: i18n._('Pound Sterling'),
    [CurrencyCode.AUD]: i18n._('Australian Dollar'),
  };
  return translations[cur];
};
